import { App, ref, Ref } from "vue";

// Define the type for the notification state
interface AppDialog {
  title: string;
  message: string;
  type: string;
  buttonLabel: string;
  onConfirm?: () => void;
}

export const dialogState: Ref<AppDialog | null> = ref(null);

const install = (app: App): void => {
  app.config.globalProperties.$dialog = (
    title: string,
    message: string,
    type = "normal",
    buttonLabel = "OK",
    onConfirm?: () => void
  ) => {
    dialogState.value = { title, message, type, buttonLabel, onConfirm };
  };
};

export default {
  install,
};
