export function toSentenceCase(str) {
  return str
    .toLowerCase()
    .split(" ")
    .map(function (word) {
      return word.charAt(0).toUpperCase() + word.slice(1);
    })
    .join(" ");
}

export function getCurrencySymbol(currency) {
  const currencySymbols = {
    GBP: "£",
    USD: "$",
    EUR: "€",
  };
  return currencySymbols[currency];
}

export function formatCurrency(number, currency) {
  const formattedCurrency = currency || "GBP";
  return getCurrencySymbol(formattedCurrency) + number.toLocaleString();
}
