import { defineStore } from "pinia";
import * as userActions from "./actions";

export const useUserStore = defineStore("user", {
  state: () => {
    return {
      authData: { given_name: "", family_name: "", email: "", picture: "", updated_at: "" },
      // Any additional fields you might expect from the server, e.g.:
      firstName: "",
      lastName: "",
      avatar: "",
      email: "",
      companyId: "",
      company: {},
      _id: "",
    };
  },
  actions: {
    setAuthData(data: any) {
      userActions.setAuthData(this.$state, data);
    },
    updateMappedData(data: any) {
      userActions.updateMappedData(this.$state, data);
    },
    async fetchUser(data: any) {
      await userActions.fetchUser(this.$state, data);
    },
  },
});

export default useUserStore;
