<template>
  <div
    :class="[
      'sidebar h-full flex grow flex-col gap-y-5 overflow-y-auto px-6 bg-brandDarkBlue-500',
      sidebarCollapsed ? 'collapsed' : '',
    ]"
  >
    <div
      class="group flex h-20 mt-8 shrink-0 items-center justify-between -ml-0.5"
      :title="sidebarCollapsed ? 'Expand sidebar' : 'Collapse sidebar'"
    >
      <img
        :class="['w-auto', sidebarCollapsed ? 'w-full' : 'h-5']"
        :src="logo.src"
        :alt="logo.alt"
        v-if="!sidebarCollapsed"
      />
      <img
        :class="['w-auto', sidebarCollapsed ? 'absolute h-5' : 'h-6']"
        :src="logo_icon.src"
        :alt="logo.alt"
        v-if="sidebarCollapsed"
      />
      <div
        v-if="!mobileMode"
        :class="[
          'collapse-icon  -mt-20 absolute bg-transparent rounded p-1.5 cursor-pointer border border-transparent bg-brandDarkBlue-500 hover:bg-brandDarkBlue-300',
          sidebarCollapsed ? ' right-5 bg-brandDarkBlue-500' : 'right-14',
        ]"
        @click.prevent="toggleSidebar"
      >
        <ChevronLeftIcon v-if="!sidebarCollapsed" class="h-4 w-4 text-white" aria-hidden="true" />
        <ChevronRightIcon v-else class="h-4 w-4 text-white" aria-hidden="true" />
      </div>
    </div>

    <nav class="flex flex-1 flex-col">
      <ul role="list" class="flex flex-1 flex-col gap-y-7">
        <li>
          <ul role="list" class="-mx-2 space-y-1">
            <li v-for="item in navigation" :key="item.name">
              <router-link
                :to="item.href"
                :class="[
                  item.current ? 'active' : '',
                  'nav-item group flex gap-x-3 rounded-md p-2 text-sm leading-6 font-semibold text-white',
                ]"
              >
                <component :is="item.icon" class="h-6 w-6 shrink-0 text-white" aria-hidden="true" />
                <span v-if="!sidebarCollapsed">
                  {{ item.name }}
                </span>
              </router-link>
              <div
                v-if="item.divider"
                class="bg-brandDarkBlue-300 w-full my-4 block"
                style="height: 1px"
              ></div>
            </li>
          </ul>
        </li>
      </ul>
    </nav>
    <UserMenuButton hideOnMobile />
  </div>
</template>

<script setup>
import useInterfaceStore from "@/store/interface";
import { computed, defineProps } from "vue";
import UserMenuButton from "../components/UserMenuButton.vue";
import { PlusCircleIcon, ChevronLeftIcon, ChevronRightIcon } from "@heroicons/vue/24/outline";

const props = defineProps({
  navigation: Array,
  hideUserMenu: Boolean,
  mobileMode: Boolean,
});

const store = useInterfaceStore();
const sidebarCollapsed = computed(() => store.sidebarCollapsed);

const logo = {
  src: "/img/logo/gm-logo-white-with-accent-1024.png",
  alt: "Growthmode",
};

const logo_icon = {
  src: "/img/logo/logo-icon-white-with-accent.png",
  alt: "Growthmode",
};

function toggleSidebar() {
  store.sidebarCollapsed = !sidebarCollapsed.value;
}
</script>

<style lang="scss">
.sidebar {
  width: 250px;
  &.collapsed {
    width: 72px;
  }

  .collapse-icon {
    opacity: 0;
  }
  &:hover .collapse-icon {
    opacity: 0.75;
    transition: opacity 0.2s ease-in-out;
  }
}
nav {
  .nav-item {
    &.active {
      background-color: rgb(255, 255, 255, 0.1);
    }
    &:not(.active) {
      &:hover {
        background-color: rgb(255, 255, 255, 0.1);
      }
    }
  }
}
</style>
