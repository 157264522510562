<template>
  <button :class="buttonClasses" :disabled="loading || disabled">
    <svg
      v-if="loading"
      :class="['animate-spin mr-2 text-white', size === 'small' ? 'h-4 w-4' : 'h-5 w-5']"
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 24 24"
    >
      <circle
        class="opacity-25"
        cx="12"
        cy="12"
        r="10"
        stroke="currentColor"
        stroke-width="4"
      ></circle>
      <path
        class="opacity-75"
        fill="currentColor"
        d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
      ></path>
    </svg>

    <span v-if="customIcon" v-html="customIcon" />
    <IconComponent v-if="icon && !loading" :class="iconClasses" />
    <CheckIcon v-if="success" :class="iconClasses" />
    {{ label }}
  </button>
</template>

<script setup>
import { computed, defineProps } from "vue";
import {
  EyeIcon,
  CheckIcon,
  DocumentDuplicateIcon,
  PencilIcon,
  PlusIcon,
  PlusSmallIcon,
  MinusIcon,
  TrashIcon,
  ChevronLeftIcon,
  ArrowTopRightOnSquareIcon,
  ArrowUturnLeftIcon,
} from "@heroicons/vue/24/solid";

import GmIconPlus from "@/components/gm-icons/GmIconPlus.vue";
import GmIconExport from "@/components/gm-icons/GmIconExport.vue";

const props = defineProps({
  label: {
    type: String,
    default: "Click here",
  },
  loading: {
    type: Boolean,
    default: false,
  },
  disabled: {
    type: Boolean,
    default: false,
  },
  icon: {
    type: String,
    default: "",
    validator: (value) => ["plus", "minus", "trash", "back"].includes(value),
  },
  size: {
    type: String,
    default: "",
    validator: (value) => ["small", "large"].includes(value),
  },
  success: {
    type: Boolean,
    default: false,
  },
  secondary: {
    type: Boolean,
    default: false,
  },
  primary: {
    type: Boolean,
    default: false,
  },
  text: {
    type: Boolean,
    default: false,
  },
  customIcon: {
    type: String,
    default: null,
  },
  iconOnly: {
    type: Boolean,
    default: false,
  },
});

// Map the icon prop to the corresponding Vue component
const iconComponents = {
  eye: EyeIcon,
  pen: PencilIcon,
  plus: GmIconPlus,
  minus: MinusIcon,
  trash: TrashIcon,
  check: CheckIcon,
  copy: DocumentDuplicateIcon,
  back: ChevronLeftIcon,
  export: GmIconExport,
  undo: ArrowUturnLeftIcon,
};

const IconComponent = computed(() => iconComponents[props.icon]);

const customIcon = computed(() => {
  if (props.customIcon === "save") {
    return `
      <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2.5" stroke-linecap="round" stroke-linejoin="round" class="h4 w-4 lucide lucide-save"><path d="M19 21H5a2 2 0 0 1-2-2V5a2 2 0 0 1 2-2h11l5 5v11a2 2 0 0 1-2 2z"/><polyline points="17 21 17 13 7 13 7 21"/><polyline points="7 3 7 8 15 8"/></svg>
    `;
  }

  return null;
});

// Optional: If you want to style the icons in a specific way
const iconClasses = computed(() => {
  let classes = "";
  if (props.size === "large") {
    classes += "w-6 h-6";
  } else if (props.size === "small") {
    classes += "w-3 h-3";

    if (!props.iconOnly) {
      classes += " mr-2 -ml-2";
    }
  } else {
    if (props.icon === "plus") {
      classes += "w-4 h-4";
    } else if (props.icon === "pen") {
      classes += "w-3 h-3";
    } else {
      classes += "w-4 h-4";
    }

    if (!props.iconOnly) {
      classes += " mr-2 -ml-2";
    }
  }

  return classes;
});

const buttonClasses = computed(() => {
  // Basic foundational classes
  let classes = "inline-flex items-center justify-center rounded-md text-white flex-shrink-0 gap-1";
  // font size and padding
  if (props.size === "large") {
    classes += " px-8 py-3";
  } else if (props.size === "small") {
    classes += " px-4 py-1.5 text-xs";
  } else {
    classes += " px-6 py-2.5 text-sm";
  }

  // colours
  if (props.disabled) {
    classes += " opacity-25 pointer-events-none cursor-default";
  } else if (props.loading) {
    classes += " opacity-50 cursor-default";
  }

  if (props.success) {
    classes += " bg-green-500";
  } else if (props.secondary) {
    classes += " bg-transparent border border-gray-300 text-gray-800 hover:border-gray-500";
  } else if (props.text) {
    classes += " bg-transparent hover:bg-transparent text-gray-800 hover:text-gray-400";
  } else if (props.primary) {
    classes += " bg-brand-500 hover:bg-brand-300";
  } else {
    classes += " bg-brandDarkBlue-300 hover:bg-brandDarkBlue-200";
  }

  if (props.text) {
    classes += " pl-0 pr-0";
  }

  return classes;
});
</script>

<style></style>
