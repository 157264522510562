import {
  createRouter as createVueRouter,
  createWebHistory,
  Router,
  RouteRecordRaw,
} from "vue-router";
import { createAuthGuard } from "@auth0/auth0-vue";
import { App } from "vue";

import SidebarLayout from "@/layouts/SidebarLayout.vue";
import PassthroughLayout from "@/layouts/PassthroughLayout.vue";

export function createRouter(app: App): Router {
  const routes: RouteRecordRaw[] = [
    {
      path: "/",
      component: SidebarLayout,
      beforeEnter: createAuthGuard(app),
      children: [
        {
          path: "",
          name: "Dashboard",
          component: () => import("@/views/DashboardView.vue"),
          meta: {
            title: "Dashboard",
          },
        },
        {
          path: "growth-plan",
          name: "Growth Plan",
          component: () => import("@/views/GrowthPlanView.vue"),
          meta: {
            title: "Growth Plan",
          },
        },
        {
          path: "strategy",
          name: "Growth Strategy",
          component: () => import("@/views/GrowthStrategyView.vue"),
          meta: {
            title: "Growth Strategy",
          },
        },
        {
          path: "experiments",
          name: "Experiments",

          children: [
            {
              path: "",
              component: () => import("@/views/ExperimentsView.vue"),
              meta: {
                title: "Experiments",
              },
            },
            {
              path: ":id",
              component: () => import("@/views/ExperimentsView.vue"),
              meta: {
                title: "Experiment",
              },
            },
          ],
        },
        {
          path: "tactics",
          name: "Tactics",
          component: () => import("@/views/TacticsView.vue"),
          meta: {
            title: "Tactics",
          },
        },

        {
          path: "ai-tools",
          name: "A.I. Tools",
          component: PassthroughLayout,
          children: [
            {
              path: "", // Default path when no tool is specified
              component: () => import("@/views/AiToolsView.vue"),
            },
            {
              path: "cro-audit/:id?",
              component: () => import("@/views/ai-tools/cro-audit/CroAuditView.vue"),
              props: true,
            },
          ],
          meta: {
            title: "A.I. Tools",
          },
        },

        {
          path: "playbooks",
          name: "Playbooks",
          component: () => import("@/views/PlaybooksView.vue"),
          meta: {
            title: "Playbooks",
          },
        },
        {
          path: "learn",
          name: "Learn",
          component: () => import("@/views/LearnView.vue"),
          meta: {
            title: "Learn",
          },
        },
        {
          path: "directory",
          name: "Directory",
          component: () => import("@/views/DirectoryView.vue"),
          meta: {
            title: "Directory",
          },
        },
        {
          path: "tools",
          name: "Tools",
          component: () => import("@/views/ToolsView.vue"),
          meta: {
            title: "Tools",
          },
        },
        {
          path: "playground",
          name: "Playground",
          component: () => import("@/views/PlaygroundView.vue"),
          meta: {
            title: "Tools",
          },
        },
      ],
    },
  ];

  const router = createVueRouter({
    routes,
    history: createWebHistory(),
  });

  router.beforeEach((to, from, next) => {
    document.title = (to.meta.title as string) || "Growthmode Marketing Platform";
    next();
  });

  return router;
}
