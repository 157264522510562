<template>
  <div class="overflow-visible grow w-full flex">
    <div
      v-if="loading"
      :class="[
        'divide-y divide-white h-52 w-full grow flex flex-col place-content-between rounded-lg bg-white border border-gray-200',
        overflow ? 'overflow-visible' : 'overflow-hidden',
      ]"
    >
      <div class="animate-pulse flex space-x-4 px-4 py-5 sm:p-6">
        <div class="flex-1 space-y-4 py-1">
          <div class="h-5 bg-gray-200 rounded w-3/4"></div>
          <div class="space-y-2">
            <div class="h-3 bg-gray-200 rounded"></div>
            <div class="h-3 bg-gray-200 rounded w-5/6"></div>
          </div>
        </div>
      </div>
    </div>
    <div
      v-else
      :class="[
        'divide-y divide-white min-h-52 w-full grow flex flex-col place-content-between rounded-lg bg-white border border-gray-200 ',
        { 'hover:shadow-lg cursor-pointer': clickable },
        { 'opacity-50 pointer-events-none': disabled },
        overflow ? 'overflow-visible' : 'overflow-hidden',
      ]"
    >
      <div
        v-if="haveThumbnail"
        :class="['thumbnail w-full', translatedThumbnailHeight]"
        :style="{ backgroundImage: `url(${thumbnail})` }"
      ></div>
      <div class="card-content">
        <slot />
      </div>
      <div>
        <slot name="footer" />
      </div>
    </div>
  </div>
</template>

<script setup>
import { defineProps, computed } from "vue";

const props = defineProps({
  loading: {
    type: Boolean,
    default: false,
  },
  disabled: {
    type: Boolean,
    default: false,
  },
  clickable: {
    type: Boolean,
    default: false,
  },
  thumbnail: {
    type: String,
    default: "",
  },
  thumbnailPosition: {
    type: String,
    default: "center center",
  },
  thumbnailHeight: {
    type: String,
    default: "medium",
    validator: (value) => ["small", "medium", "large"].includes(value),
  },
  overflow: {
    type: Boolean,
    default: false,
  },
});

const haveThumbnail = computed(() => props.thumbnail !== "");
const translatedThumbnailHeight = computed(() => {
  switch (props.thumbnailHeight) {
    case "small":
      return "h-24";
    case "medium":
      return "h-32";
    case "large":
      return "h-60";

    default:
      return "h-32";
  }
});
</script>

<style lang="scss" scoped>
.thumbnail {
  background-size: 100% auto;
  background-repeat: no-repeat;
}
.card-content {
  &::empty {
    padding: 0 !important;
  }
}
</style>
