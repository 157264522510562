import { defineStore } from "pinia";

export const useInterfaceStore = defineStore("interface", {
  state: () => {
    return {
      sidebarCollapsed: false,
      tabs: [],
      activeTab: null,
      pageHeaderAction: null,
      modalUnsavedChanges: false,
    };
  },
});

export default useInterfaceStore;
