import tooltipDirective from "./tooltip";
import autoExpandDirective from "./autoexpand";

// register all directives
const directives = (app: any) => {
  tooltipDirective(app);
  autoExpandDirective(app);
};

export default directives;
