<template>
  <teleport to="body">
    <div style="z-index: 1001">
      <TransitionRoot as="template" :show="dialogOpen">
        <Dialog as="div" class="relative z-50" @close="closeDialog">
          <TransitionChild
            as="template"
            enter="ease-out duration-100"
            enter-from="opacity-0"
            enter-to="opacity-100"
            leave="ease-in duration-100"
            leave-from="opacity-100"
            leave-to="opacity-0"
          >
            <div class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </TransitionChild>

          <div class="fixed inset-0 z-10 w-screen overflow-y-auto">
            <div
              class="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0"
            >
              <TransitionChild
                as="template"
                enter="ease-out duration-100"
                enter-from="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                enter-to="opacity-100 translate-y-0 sm:scale-100"
                leave="ease-in duration-100"
                leave-from="opacity-100 translate-y-0 sm:scale-100"
                leave-to="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              >
                <DialogPanel
                  class="relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg sm:p-6"
                >
                  <div class="sm:flex sm:items-start">
                    <div
                      class="mx-auto flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full bg-red-100 sm:mx-0 sm:h-10 sm:w-10"
                    >
                      <ExclamationTriangleIcon class="h-6 w-6 text-red-600" aria-hidden="true" />
                    </div>
                    <div class="mt-3 text-center sm:ml-4 sm:mt-0 sm:text-left">
                      <DialogTitle
                        as="h3"
                        class="text-base font-semibold leading-6 text-gray-900"
                        >{{ title }}</DialogTitle
                      >
                      <div class="mt-2">
                        <p class="text-sm text-gray-500">
                          {{ message }}
                        </p>
                      </div>
                    </div>
                  </div>
                  <div class="mt-5 sm:ml-10 sm:mt-4 sm:flex sm:pl-4">
                    <button
                      type="button"
                      class="inline-flex w-full justify-center rounded-md bg-brand-500 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-brand-400 sm:w-auto"
                      @click.stop="handleConfirm"
                    >
                      {{ buttonLabel }}
                    </button>
                    <button
                      v-if="type === 'confirm'"
                      type="button"
                      class="mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:ml-3 sm:mt-0 sm:w-auto"
                      @click.stop="closeDialog"
                      ref="cancelButtonRef"
                    >
                      Cancel
                    </button>
                  </div>
                </DialogPanel>
              </TransitionChild>
            </div>
          </div>
        </Dialog>
      </TransitionRoot>
    </div>
  </teleport>
</template>

<script setup>
import { ref, watch, defineProps, onMounted, nextTick } from "vue";
import { Dialog, DialogPanel, DialogTitle, TransitionChild, TransitionRoot } from "@headlessui/vue";
import { ExclamationTriangleIcon } from "@heroicons/vue/24/outline";
import { dialogState } from "@/plugins/dialogPlugin";

const dialogOpen = ref(false);
const title = ref("");
const message = ref("");
const type = ref("normal"); // Valid options: normal, success, error, warning
const buttonLabel = ref("OK");

watch(dialogState, (newValue) => {
  if (newValue) {
    dialogOpen.value = true;
    title.value = newValue.title;
    message.value = newValue.message;
    type.value = newValue.type;
    buttonLabel.value = newValue.buttonLabel;
  }
});

function closeDialog(event) {
  event.stopPropagation();
  dialogOpen.value = false;
}

function handleConfirm(event) {
  event.stopPropagation();
  if (dialogState.value?.onConfirm) {
    dialogState.value.onConfirm();
    dialogOpen.value = false; // Close the dialog
  }
}
</script>
