<template>
  <div :class="{ 'calendar-task in-list': inTable }">
    <label
      v-show="hasLabel"
      :for="name"
      class="block text-sm font-semibold leading-5 text-gray-900 mb-3 mt-2"
    >
      {{ label }}
    </label>
    <div :class="['gm-input', themeClasses, { 'ring-red-500 ring-2': hasError }]">
      <div
        v-if="type === 'currency'"
        class="absolute inset-y-0 left-0 text-sm text-gray-500 h-full flex items-center pl-3"
      >
        {{ computedCurrencySymbol }}
      </div>
      <input
        v-if="computedType !== 'textarea' && computedType !== 'date'"
        :type="computedType"
        :id="id"
        :name="name"
        :placeholder="placeholder"
        :required="required"
        :disabled="disabled"
        :value="modelValue"
        @input="$emit('update:modelValue', $event.target.value)"
        @focus="$emit('focus')"
        :class="['h-6', baseClasses, extraClasses, textBlack ? 'text-black' : 'text-gray-600']"
        v-auto-expand="autoExpand"
        @keyup="type === 'currency' ? formatNumberInput($event) : null"
        :min="min"
        :max="max"
        :step="step"
      />
      <textarea
        v-if="computedType === 'textarea'"
        :type="computedType"
        :id="id"
        :name="name"
        :placeholder="placeholder"
        :required="required"
        :disabled="disabled"
        :value="modelValue"
        @input="$emit('update:modelValue', $event.target.value)"
        @focus="$emit('focus')"
        :class="[baseClasses, extraClasses, textBlack ? 'text-black' : 'text-gray-600']"
        :rows="computedRows"
        v-auto-expand="autoExpand"
      />

      <div class="due-date relative flex items-center group" v-if="computedType === 'date'">
        <flat-pickr
          v-if="computedType === 'date'"
          v-model="dateValue"
          :class="[
            'gm-calendar-input',
            baseClasses,
            extraClasses,
            isEmpty ? 'gm-calendar-empty' : '',
          ]"
          :placeholder="placeholder"
          :disabled="disabled"
          @input="updateDate"
          @focus="$emit('focus')"
          :config="dateConfig"
        />
        <div
          v-if="formattedDate !== 'Not set'"
          class="clear-due-date absolute right-3 top-2 cursor-pointer"
          v-tooltip="{ text: 'Clear date' }"
          @click.stop="clearDate"
        >
          <XMarkIcon
            class="-mr-1 h-3.5 w-3.5 text-gray-400 hover:text-gray-600 rounded-full border border-gray-400 hover:border-gray-600"
          />
        </div>
        <div
          v-if="formattedDate === 'Not set'"
          :class="[
            'absolute right-2 text-gray-400 p-0 flex items-center group-hover:text-gray-600',
            inTable ? 'w-3 h-3' : 'w-4 h-4',
          ]"
        >
          <CalendarIcon />
        </div>
      </div>

      <div class="absolute inset-y-0 right-0 flex py-1.5 pr-1.5">
        <slot name="append"></slot>
      </div>
    </div>
    <div v-if="hasError" class="text-sm mt-1 flex w-full justify-start text-red-600">
      {{ hasError }}
    </div>
    <div v-if="hasNote" class="text-xs mt-1 flex w-full text-gray-500">
      {{ note }}
    </div>
  </div>
</template>

<script setup>
import {
  defineProps,
  defineEmits,
  onMounted,
  onUnmounted,
  computed,
  toRefs,
  ref,
  watch,
} from "vue";
import flatPickr from "vue-flatpickr-component";
import "flatpickr/dist/flatpickr.css";
import { getCurrencySymbol } from "@/utils/helpers";
import { CalendarIcon, XMarkIcon } from "@heroicons/vue/24/outline";

const props = defineProps({
  modelValue: String, // Add the modelValue prop
  type: {
    type: String,
    validator: (prop) =>
      ["date", "text", "url", "email", "password", "textarea", "currency"].includes(prop),
  },
  id: {
    type: String,
  },
  name: {
    type: String,
  },
  placeholder: {
    type: String,
    default: null,
  },
  note: {
    type: String,
    default: "",
  },
  required: {
    type: Boolean,
    default: false,
  },
  disabled: {
    type: Boolean,
    default: false,
  },
  label: {
    type: String,
    default: "",
  },
  subLabel: {
    type: String,
    default: "",
  },
  size: {
    type: String,
    default: null,
    validator: (prop) => ["large"].includes(prop),
  },
  rows: {
    type: Number,
    default: 3,
  },
  hasError: {
    type: String,
    default: null,
  },
  extraClasses: {
    type: String,
    default: null,
  },
  autoExpand: {
    type: Boolean,
    default: false,
  },
  camouflaged: {
    type: Boolean,
    default: false,
  },
  dateFormat: {
    type: String,
    default: "F j, Y",
  },
  datePickerRef: Object,
  setFlatpickrRef: Function,
  taskId: [String, Number],
  inTable: {
    type: Boolean,
    default: false,
  },
  currency: {
    type: String,
    default: "USD",
  },
  min: {
    type: Number,
    default: null,
  },
  max: {
    type: Number,
    default: null,
  },
  step: {
    type: Number,
    default: null,
  },
  editOnHover: {
    type: Boolean,
    default: false,
  },
  textBlack: {
    type: Boolean,
    default: false,
  },
});

const { label } = toRefs(props);
const emit = defineEmits(["update:modelValue", "focus", "change"]);
const dateValue = ref(null);

const hasNote = computed(() => props.note.length > 0);
const hasLabel = computed(() => label.value.length > 0);
const isEmpty = computed(() => !props.modelValue || props.modelValue === "Not set");
const computedType = computed(() => {
  if (props.autoExpand && props.type === "text") {
    return "textarea";
  }
  return props.type;
});
const computedRows = computed(() => {
  if (props.autoExpand && props.type === "text") {
    return 1;
  }
  return props.rows;
});

// Function to format currency input
const computedCurrencySymbol = computed(() => getCurrencySymbol(props.currency));

function formatNumberInput(event) {
  // Get the current value of the input field
  let input = event.target.value;

  // Remove any characters that are not digits or a period
  let numbersAndDotsOnly = input.replace(/[^\d.]/g, "");

  // Split the string at the period to separate the integer part from the decimal part
  let parts = numbersAndDotsOnly.split(".");

  // If there is a decimal part, truncate it to two digits
  if (parts[1]) {
    parts[1] = parts[1].substring(0, 2);
  }

  // Replace the integer part with a comma-separated string
  parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");

  // Rejoin the integer and decimal parts
  event.target.value = parts.join(".");
}

const baseClasses = computed(() => {
  let classes =
    "text-sm leading-5 outline-none block w-full border-0 placeholder:text-gray-600 focus:outline-none bg-transparent resize-none";
  if (props.type === "currency") {
    classes += " pl-4";
  } else {
    classes += " px-0";
  }

  if (props.disabled) {
    classes += " opacity-50 pointer-events-none";
  }
  return classes;
});

const themeClasses = computed(() => {
  let classes = "relative  focus-within:z-10 focus:outline-none w-full ";
  if (props.camouflaged) {
    classes += "bg-transparent ";
  } else if (props.editOnHover) {
    classes +=
      "edit-on-hover bg-transparent py-1 focus-within:mx-0 border border-transparent hover:border-gray-200 focus-within:border-gray-200 rounded-md";
  } else {
    if (props.type !== "date") {
      classes += "px-3 py-2.5 ";
    }
    classes +=
      "rounded-md bg-gray-100 ring-inset ring-gray-100 ring-1 focus-within:ring-2 focus-within:ring-brandDarkBlue-500";
  }
  return (classes += " " + props.extraClasses);
});

// Date stuff

const dateConfig = ref({
  wrap: true, // set wrap to true only when using 'input-group'
  altFormat: props.dateFormat,
  altInput: true,
  dateFormat: "Y-m-d",
});

watch(
  () => props.modelValue,
  (newVal) => {
    if (!dateValue.value || newVal !== dateValue.value) {
      dateValue.value = newVal;
    }
  },
  { immediate: true }
);

watch(dateValue, (newVal) => {
  if (!props.modelValue || newVal !== props.modelValue) {
    emit("update:modelValue", newVal);
  }
});

function emitReadyDatePicker(instance) {
  console.log("Emitting ready date picker", instance);
}

const formattedDate = computed(() => {
  if (dateValue.value) {
    return dateValue.value;
  }

  return "Not set";
});

function clearDate() {
  dateValue.value = null;
  emit("change", null);
}

function updateDate(event) {
  const fDate = event.target.value;
  dateValue.value = fDate;
  emit("update:modelValue", fDate);
  emit("change", fDate);
}
</script>

<style lang="scss">
input {
  &:disabled {
    opacity: 0.5;
    background: inherit;
  }
}
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  transition: background-color 5000s ease-in-out 0s;
}

[type="text"]:focus,
input:where(:not([type])):focus,
[type="email"]:focus,
[type="url"]:focus,
[type="password"]:focus,
[type="number"]:focus,
[type="date"]:focus,
[type="datetime-local"]:focus,
[type="month"]:focus,
[type="search"]:focus,
[type="tel"]:focus,
[type="time"]:focus,
[type="week"]:focus,
[multiple]:focus,
textarea:focus,
select:focus {
  outline: none;
  box-shadow: none;
}

/* Datepicker styles */
$text-color: #111827;
$placeholder-color: #4b5563;
$growthmode-brand-color: #ff00bb;
$growthmode-blue: #0206b2;

.edit-on-hover {
  &:hover,
  &:focus-within {
    @apply px-1;
  }
  &:focus-within {
    @apply ring-2 ring-brandDarkBlue-500;
  }
}

.gm-calendar-input {
  border: none;
  width: 100%;
  line-height: 1.5rem;
  font-size: inherit;
  padding-top: 0.625rem;
  padding-bottom: 0.625rem;
  padding-left: 0.75rem;
  padding-right: 0.75rem;
  display: flex;
  align-items: center;
  border-radius: 0;
  min-width: 0;
  min-height: 44px;
  cursor: pointer;
  font-size: 0.865rem;
}

.flatpickr-day.selected {
  background-color: $growthmode-brand-color;
  border-color: $growthmode-brand-color;
}

.calendar-task {
  &.in-list {
    max-width: 5.5rem;
    width: auto;
    padding: 0;
    .gm-calendar-input {
      font-size: 0.75rem;
      margin: 0;
      white-space: nowrap;
      padding: 0;
      text-align: center;
      justify-content: center;
      min-height: 0;
    }
    .gm-input {
      background: transparent;
      border: none;
      --tw-ring-opacity: 0;
    }
    .clear-due-date {
      right: -1rem;
      margin-top: -0.07rem;
      background-color: white;
      opacity: 0;
    }
    &:hover {
      opacity: 0.75;
      .clear-due-date {
        opacity: 1;
      }
    }
  }
}
</style>
