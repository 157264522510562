<template>
  <div :class="['w-full grid  gap-4 pb-8', computedCols]">
    <slot></slot>
  </div>
</template>

<script setup>
import { defineProps, computed } from "vue";

const props = defineProps({
  cols: {
    type: Number,
    default: 3,
  },
});

const computedCols = computed(() => {
  const smallScreenCols = Math.ceil(props.cols / 2);
  return `grid-cols-1 md:grid-cols-${smallScreenCols} xl:grid-cols-${props.cols}`;
});
</script>
