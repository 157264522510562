// userActions.ts

export function setAuthData(state: any, data: any) {
  state.authData = data;
}

export function updateMappedData(state: any, data: any) {
  const authFields = ["given_name", "family_name", "email", "picture", "updated_at"];
  authFields.forEach((field) => {
    if (data[field]) {
      state.authData[field] = data[field];
      delete data[field];
    }
  });

  for (const key in data) {
    state[key] = data[key];
  }
}

export async function fetchUser(state: any, auth0Data: any) {
  const mappedUserData = {
    firstName: auth0Data.given_name,
    lastName: auth0Data.family_name,
    email: auth0Data.email,
    avatar: auth0Data.picture,
    updated: auth0Data.updated_at,
  };

  try {
    const response = await fetch(`${process.env.VUE_APP_API_URL}/api/users/sync`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        user: mappedUserData,
      }),
    });

    const data = await response.json();

    if (data && data.user) {
      setAuthData(state, auth0Data);
      updateMappedData(state, data.user);
    }
  } catch (err) {
    console.error("Error fetching user:", err);
  }
}
