<template>
  <div
    class="w-7 h-7 flex items-center justify-center border rounded border-gray-300 bg-white shadow-md hover:bg-gray-200 hover:border-gray-300 cursor-pointer"
    v-tooltip="{ text: label }"
  >
    <IconComponent :class="iconClasses" />
  </div>
</template>

<script setup>
import { computed, defineProps } from "vue";
import {
  EyeIcon,
  CheckIcon,
  DocumentDuplicateIcon,
  PencilIcon,
  PlusIcon,
  PlusSmallIcon,
  MinusIcon,
  TrashIcon,
  ChevronLeftIcon,
  ArrowTopRightOnSquareIcon,
} from "@heroicons/vue/24/solid";

import GmIconPlus from "@/components/gm-icons/GmIconPlus.vue";
import GmIconExport from "@/components/gm-icons/GmIconExport.vue";

const props = defineProps({
  type: {
    type: String,
    default: "edit",
    validator: (value) => ["edit", "delete", "add"].includes(value),
  },
  label: {
    type: String,
    default: null,
  },
});

const iconComponents = {
  eye: EyeIcon,
  pen: PencilIcon,
  plus: GmIconPlus,
  minus: MinusIcon,
  trash: TrashIcon,
  check: CheckIcon,
  copy: DocumentDuplicateIcon,
  back: ChevronLeftIcon,
  export: GmIconExport,
};

const computedIcon = computed(() => {
  switch (props.type) {
    case "edit":
      return "pen";
    case "delete":
      return "trash";
    case "add":
      return "plus";
    default:
      return "edit";
  }
});

const IconComponent = computed(() => iconComponents[computedIcon.value]);

const iconClasses = computed(() => {
  let iconClasses = "text-gray-500 ";
  iconClasses += "w-3 h-3";
  return iconClasses;
});
</script>

<style></style>
