<template>
  <div class="sm:rounded-lg bg-brandDarkBlue-50">
    <div class="px-4 py-5 sm:p-6 w-full flex justify-between items-center">
      <div>
        <h3 class="text-lg font-semibold mb-0 text-brandDarkBlue-500">{{ title }}</h3>
        <div v-if="haveContent" class="max-w-xl text-sm text-gray-600 mt-1" v-html="content"></div>
      </div>
      <div class="mt-5 sm:ml-6 sm:mt-0 sm:flex sm:flex-shrink-0 sm:items-center">
        <slot name="actions"></slot>
      </div>
    </div>
  </div>
</template>

<script setup>
import { defineProps, computed } from "vue";

const props = defineProps({
  title: {
    type: String,
    required: true,
  },
  content: {
    type: String,
    required: false,
    default: "",
  },
});

const haveContent = computed(() => props.content !== "");
</script>
