const tooltipDirective = (app: any) => {
  app.directive("tooltip", {
    mounted(el: any, binding: any) {
      init(el, binding);
    },
    updated(el: any, binding: any) {
      init(el, binding);
    },
  });
};

function init(el: any, binding: any) {
  // Extract the properties from the binding.value object
  const { text = "", position = "top", size = "default" } = binding.value || {};

  // Set attributes for position, text, and size
  el.setAttribute("position", position);
  el.setAttribute("tooltip", text);
  el.setAttribute("tooltip-size", size);
}

export default tooltipDirective;
