import { App, ref, Ref } from "vue";

// Define the type for the notification state
interface AppNotification {
  message: string;
  type: string;
  autoHide: boolean;
}

export const notificationState: Ref<AppNotification | null> = ref(null);

const install = (app: App): void => {
  app.config.globalProperties.$notify = (message: string, type = "success", autoHide = true) => {
    notificationState.value = { message, type, autoHide };
  };
};

export default {
  install,
};
