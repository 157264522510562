<template>
  <svg
    :class="[
      'animate-spin mr-2',
      color,
      { 'h-4 w-4': size === 'small' },
      { 'h-5 w-5': size === 'medium' },
      { 'h-7 w-7': size === 'large' },
    ]"
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
  >
    <circle
      class="opacity-25"
      cx="12"
      cy="12"
      r="10"
      stroke="currentColor"
      stroke-width="4"
    ></circle>
    <path
      class="opacity-75"
      fill="currentColor"
      d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
    ></path>
  </svg>
</template>

<script setup>
import { computed, defineProps } from "vue";

const props = defineProps({
  size: {
    type: String,
    default: "medium",
    validator: (value) => ["small", "large"].includes(value),
  },
  color: {
    type: String,
    default: "brandDarkBlue-500",
    validator: (value) =>
      [
        "gray",
        "red",
        "yellow",
        "green",
        "blue",
        "indigo",
        "purple",
        "brand-500",
        "brandDarkBlue-500",
      ].includes(value),
  },
});

const color = computed(() => {
  if (props.color) {
    return `text-${props.color}`;
  }

  return "text-brandDarkBlue-500";
});
</script>

<style></style>
