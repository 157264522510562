<template>
  <TransitionRoot as="template" :show="isOpen">
    <Dialog as="div" class="relative z-10">
      <div class="fixed inset-0" />

      <div class="fixed inset-0 overflow-hidden">
        <div class="absolute inset-0 overflow-hidden">
          <div class="pointer-events-none fixed inset-y-0 right-0 flex max-w-full pl-10 sm:pl-16">
            <TransitionChild
              as="template"
              enter="transform transition ease-in-out duration-300 sm:duration-400"
              enter-from="translate-x-full"
              enter-to="translate-x-0"
              leave="transform transition ease-in-out duration-300 sm:duration-400"
              leave-from="translate-x-0"
              leave-to="translate-x-full"
            >
              <DialogPanel class="pointer-events-auto w-screen max-w-2xl min-h-full">
                <div class="flex h-full min-h-full flex-1 flex-col bg-white pb-6 shadow-xl">
                  <div class="px-4 sm:px-6 bg-brandDarkBlue-500 pt-6">
                    <div class="flex items-start justify-between">
                      <DialogTitle class="text-lg text-white font-semibold leading-6">{{
                        panelTitle
                      }}</DialogTitle>
                      <div class="ml-3 flex h-7 items-center">
                        <button
                          type="button"
                          class="relative rounded-md text-brandDarkBlue-100 hover:text-white focus:outline-none"
                          @click.stop="closePanel"
                        >
                          <span class="absolute -inset-2.5" />
                          <span class="sr-only">Close panel</span>
                          <XMarkIcon class="h-6 w-6" aria-hidden="true" />
                        </button>
                      </div>
                    </div>
                  </div>
                  <div class="relative py-6 flex-1 px-4 sm:px-6 overflow-y-auto">
                    <!-- Your content -->
                    <slot />
                  </div>
                  <div
                    v-if="haveActionButton"
                    class="flex flex-shrink-0 justify-end px-6 pt-6 border-t"
                  >
                    <gm-button
                      :label="actionButtonLabel"
                      primary
                      :disabled="actionButtonDisabled"
                      :loading="actionButtonLoading"
                      @click="clickActionButton"
                    />
                  </div>
                </div>
              </DialogPanel>
            </TransitionChild>
          </div>
        </div>
      </div>
    </Dialog>
  </TransitionRoot>
</template>

<script setup>
import { computed, defineEmits, defineProps, ref } from "vue";
import { Dialog, DialogPanel, DialogTitle, TransitionChild, TransitionRoot } from "@headlessui/vue";
import { XMarkIcon } from "@heroicons/vue/24/outline";
import useInterfaceStore from "@/store/interface";
import { useDialog } from "@/plugins/useDialog";

const props = defineProps({
  modelValue: {
    type: Boolean,
    default: false,
  },
  panelTitle: {
    type: String,
    default: "",
  },
  actionButtonLabel: {
    type: String,
    default: "",
  },
  actionButtonDisabled: {
    type: Boolean,
    default: false,
  },
  actionButtonLoading: {
    type: Boolean,
    default: false,
  },
});

const emit = defineEmits(["update:modelValue", "closed", "click-action-button"]);

const interfaceStore = useInterfaceStore();
const $dialog = useDialog();

const isOpen = computed({
  get: () => props.modelValue,
  set: (val) => emit("update:modelValue", val),
});

const haveActionButton = computed(() => props.actionButtonLabel !== "");

function closePanel() {
  if (interfaceStore.modalUnsavedChanges) {
    $dialog(
      "Unsaved Changes",
      "You have unsaved changes, which will be lost. Are you sure you want to proceed?",
      "confirm",
      "Close without saving",
      () => {
        isOpen.value = false;
        emit("closed");
      }
    );
  } else {
    isOpen.value = false;
    emit("closed");
  }
}

function clickActionButton() {
  emit("click-action-button");
}
</script>
