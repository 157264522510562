<template>
  <Menu
    as="div"
    :class="['relative inline-block px-0 text-left md:-mx-6', { 'hidden lg:block': hideOnMobile }]"
  >
    <div>
      <transition
        enter-active-class="transition ease-out duration-100"
        enter-from-class="transform opacity-0 translate-y-8"
        enter-to-class="transform opacity-100 translate-y-0"
        leave-active-class="transition ease-in duration-75"
        leave-from-class="transform opacity-100 translate-y-0"
        leave-to-class="transform opacity-0 translate-y-8"
      >
        <MenuItems
          :class="[
            'sub-menu fixed  w-72 lg:w-50 z-10 lg:bottom-0 mt-12 lg:mt-auto  mx-2 origin-top divide-y divide-gray-200 rounded-sm bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none',
            sidebarCollapsed ? '' : 'left-0 lg:mb-16',
            mobileMode ? 'mt-14' : '',
          ]"
          :style="[
            sidebarCollapsed ? 'left: 64px; width: 234px' : 'width: 234px',
            mobileMode ? 'left: auto; right: 0' : '',
          ]"
        >
          <div class="py-1">
            <MenuItem v-slot="{ active }">
              <a
                href="#"
                :class="[
                  active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                  'block px-4 py-2 text-sm',
                ]"
                >View profile</a
              >
            </MenuItem>
            <MenuItem v-slot="{ active }">
              <a
                href="#"
                :class="[
                  active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                  'block px-4 py-2 text-sm',
                ]"
                >Settings</a
              >
            </MenuItem>
            <MenuItem v-slot="{ active }">
              <a
                href="#"
                :class="[
                  active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                  'block px-4 py-2 text-sm',
                ]"
                >Notifications</a
              >
            </MenuItem>
          </div>
          <div class="py-1">
            <MenuItem v-slot="{ active }">
              <a
                href="#"
                @click.prevent="logout"
                :class="[
                  active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                  'group flex place-content-between items-center px-4 py-2 text-sm',
                ]"
              >
                Logout
                <ArrowRightOnRectangleIcon
                  class="ml-3 h-5 w-5 text-gray-900 group-hover:text-gray-500"
                  aria-hidden="true"
                />
              </a>
            </MenuItem>
          </div>
        </MenuItems>
      </transition>
      <MenuButton
        class="menu-button group w-full rounded-full lg:rounded-none px-2 py-2 lg:py-0 lg:px-5 h-full lg:h-16 text-left text-sm font-medium focus:outline-none"
      >
        <span class="flex w-full items-center justify-between">
          <span class="flex min-w-0 items-center justify-between space-x-3">
            <img
              class="h-8 w-8 rounded-full bg-gray-50"
              :src="userStore.avatar"
              :alt="`${userStore.firstName} ${userStore.lastName}`"
              :title="`${userStore.firstName} ${userStore.lastName}`"
            />
            <span v-if="!sidebarCollapsed" class="min-w-0 flex-1 flex-col hidden lg:flex">
              <span class="truncate text-sm font-medium text-white"
                >{{ userStore.firstName }} {{ userStore.lastName }}</span
              >
            </span>
          </span>
          <ChevronUpDownIcon
            v-if="!sidebarCollapsed"
            class="h-5 w-5 hidden lg:flex flex-shrink-0 text-white"
            aria-hidden="true"
          />
        </span>
      </MenuButton>
    </div>
  </Menu>
</template>

<script setup>
import useInterfaceStore from "@/store/interface";
import useUserStore from "@/store/user";
import { useAuth0 } from "@auth0/auth0-vue";
import { defineProps, computed } from "vue";
import { Menu, MenuButton, MenuItem, MenuItems } from "@headlessui/vue";
import { ChevronUpDownIcon } from "@heroicons/vue/20/solid";

// Component properties
const props = defineProps({
  hideOnMobile: Boolean,
  mobileMode: Boolean,
});

// Interface store for UI related data
const interfaceStore = useInterfaceStore();
const sidebarCollapsed = computed(() => interfaceStore.sidebarCollapsed);

// Auth0 integration
const auth0 = useAuth0();

// User store for managing user-related data
const userStore = useUserStore();

// Logout function
function logout() {
  auth0.logout({
    logoutParams: {
      returnTo: window.location.origin,
    },
  });
}
</script>

<style lang="scss" scoped>
.menu-button {
  &:hover {
    background-color: rgba(255, 255, 255, 0.1);
  }
}
</style>
