import { createApp } from "vue";
import App from "./App.vue";
import "./registerServiceWorker";
import { createRouter } from "./router";
import { createAuth0 } from "@auth0/auth0-vue";
const pinia = createPinia();
import "./assets/tailwind.css";
import "./index.css";
import { createPinia } from "pinia";
// Import custom ui components
import * as gmComponents from "@/components/gm-components";
import AppNotification from "@/components/AppNotification.vue";
import notificationPlugin from "@/plugins/notificationPlugin";
import dialogPlugin from "@/plugins/dialogPlugin";
import AppDialog from "@/components/AppDialog.vue";

// Import directives
import directives from "./directives/";

// Initialize the Vue app
const app = createApp(App);

// Register all gm components globally
Object.entries(gmComponents).forEach(([name, component]) => {
  app.component(name, component);
});

app.component("global-notification", AppNotification);
app.component("global-dialog", AppDialog);

app.config.warnHandler = () => {
  // suppress warnings
};
console.warn = () => {
  // suppress console warnings
};

// register all directives
directives(app);

app
  .use(createRouter(app))
  .use(
    createAuth0({
      domain: process.env.VUE_APP_AUTH0_DOMAIN as string,
      clientId: process.env.VUE_APP_AUTH0_CLIENT_ID as string,
      authorizationParams: {
        redirect_uri: window.location.origin,
      },
    })
  )
  .use(pinia)
  .use(notificationPlugin)
  .use(dialogPlugin)
  .mount("#app");
