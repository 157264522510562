import { getCurrentInstance } from "vue";

export function useDialog() {
  const instance = getCurrentInstance();
  const $dialog = instance?.appContext.config.globalProperties.$dialog;

  if (!$dialog) {
    throw new Error("No global $dialog function found.");
  }

  return $dialog;
}
