const autoExpandDirective = (app: any) => {
  app.directive("auto-expand", {
    mounted(el: any, binding: any) {
      if (shouldAutoExpand(el, binding)) {
        initAutoExpand(el);
      }
    },
    updated(el: any, binding: any) {
      if (shouldAutoExpand(el, binding)) {
        updateAutoExpand(el);
      }
    },
  });
};

function shouldAutoExpand(el: any, binding: any) {
  // We only auto-expand textareas, not inputs, based on your requirements
  return (
    (el.tagName.toLowerCase() === "text" || el.tagName.toLowerCase() === "textarea") &&
    binding.value
  );
}

function initAutoExpand(el: any) {
  const resizeTextarea = (event: any) => {
    event.target.style.height = "auto";
    event.target.style.height = event.target.scrollHeight + "px";
  };
  el.addEventListener("input", resizeTextarea);
  resizeTextarea({ target: el }); // Initialize auto-resize
}

function updateAutoExpand(el: any) {
  // Adjust the height if the content changes
  el.style.height = "auto";
  el.style.height = el.scrollHeight + "px";
}

export default autoExpandDirective;
