<template>
  <div class="h-full">
    <div v-if="userLoading" class="flex h-screen items-center justify-center w-full bg-white">
      <div class="inline-flex items-center transition ease-in-out duration-150" disabled="">
        <svg
          class="animate-spin -ml-1 mr-3 h-8 w-8 text-brandDarkBlue-500"
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
        >
          <circle
            class="opacity-25"
            cx="12"
            cy="12"
            r="10"
            stroke="currentColor"
            stroke-width="4"
          ></circle>
          <path
            class="opacity-75"
            fill="currentColor"
            d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
          ></path>
        </svg>
      </div>
    </div>
    <div v-else>
      <TransitionRoot as="template" :show="sidebarOpen">
        <Dialog as="div" class="relative z-50 lg:hidden" @close="sidebarOpen = false">
          <TransitionChild
            as="template"
            enter="transition-opacity ease-linear duration-300"
            enter-from="opacity-0"
            enter-to="opacity-80"
            leave="transition-opacity ease-linear duration-300"
            leave-from="opacity-80"
            leave-to="opacity-0"
          >
            <div class="fixed inset-0 bg-gray-900 bg-opacity-80" />
          </TransitionChild>

          <div class="fixed inset-0 flex">
            <TransitionChild
              as="template"
              enter="transition ease-in-out duration-300 transform"
              enter-from="-translate-x-full"
              enter-to="translate-x-0"
              leave="transition ease-in-out duration-300 transform"
              leave-from="translate-x-0"
              leave-to="-translate-x-full"
            >
              <DialogPanel class="relative mr-16 flex w-full max-w-xs flex-1">
                <TransitionChild
                  as="template"
                  enter="ease-in-out duration-300"
                  enter-from="opacity-0"
                  enter-to="opacity-100"
                  leave="ease-in-out duration-300"
                  leave-from="opacity-100"
                  leave-to="opacity-0"
                >
                  <div class="absolute top-0 left-full flex w-16 justify-center pt-5">
                    <button
                      type="button"
                      class="-m-2 -ml-72 p-3.5 opacity-50"
                      @click="sidebarOpen = false"
                    >
                      <span class="sr-only">Close sidebar</span>
                      <XMarkIcon class="h-5 w-5 text-white" aria-hidden="true" />
                    </button>
                  </div>
                </TransitionChild>
                <!-- Sidebar component (mobile flyout), swap this element with another sidebar if you like -->
                <SideBar
                  :navigation="navigation"
                  :projects="projects"
                  hideUserMenu="true"
                  mobileMode
                />
              </DialogPanel>
            </TransitionChild>
          </div>
        </Dialog>
      </TransitionRoot>

      <!-- Static sidebar for desktop -->
      <div
        :class="[
          'hidden lg:fixed lg:inset-y-0 lg:z-50 lg:flex  lg:flex-col',
          sidebarCollapsed ? '' : 'lg:w-72',
        ]"
      >
        <!-- Sidebar component, swap this element with another sidebar if you like -->
        <SideBar :navigation="navigation" :projects="projects" :collapsed="sidebarCollapsed" />
      </div>

      <div
        class="sticky top-0 z-40 flex items-center justify-between gap-x-6 bg-brandDarkBlue-500 py-1 px-4 shadow-sm sm:px-6 lg:hidden"
      >
        <button
          type="button"
          class="-m-2.5 p-2.5 text-white lg:hidden hover:opacity-50"
          @click="sidebarOpen = true"
        >
          <span class="sr-only">Open sidebar</span>
          <Bars3Icon class="h-6 w-6" aria-hidden="true" />
        </button>
        <div class="flex mx-auto justify-center flex-1 text-md font-semibold leading-6 text-white">
          <img class="h-6" :src="logo.src" :alt="logo.alt" />
        </div>
        <UserMenuButton mobileMode />
      </div>

      <main
        :class="[
          ' min-h-screen bg-white transition ease-in-out duration-300 px-6',
          sidebarCollapsed ? 'sidebar-collapsed' : 'sidebar-open',
        ]"
      >
        <!-- Your content -->
        <router-view />
      </main>
    </div>
  </div>
</template>

<script setup>
// Vue Composition API imports
import { defineProps, computed, onMounted, watch, ref, reactive } from "vue";
import { useRoute } from "vue-router"; // Vue Router hook

// Headless UI components for dialogs and transitions
import { Dialog, DialogPanel, TransitionChild, TransitionRoot } from "@headlessui/vue";

// Auth0 Vue Composition API
import { useAuth0 } from "@auth0/auth0-vue";

// Heroicons for sidebar navigation
import {
  HomeIcon,
  MapIcon,
  RocketLaunchIcon,
  LightBulbIcon,
  CpuChipIcon,
  BookOpenIcon,
  AcademicCapIcon,
  Squares2X2Icon,
  WrenchScrewdriverIcon,
  BeakerIcon,
} from "@heroicons/vue/24/outline";

// Components
import SideBar from "../components/SideBar.vue";
import UserMenuButton from "../components/UserMenuButton.vue";

// Pinia store imports
import useInterfaceStore from "@/store/interface";
import useUserStore from "@/store/user";

// Auth0 hook
const auth0 = useAuth0();

// Loading state for user data synchronization
const userLoading = ref(true);

// User store from Pinia
const userStore = useUserStore();

// Syncs user data with the store
async function syncUser(userData) {
  userLoading.value = true;
  await userStore.fetchUser(userData);
  userLoading.value = false;
}

// Interface store from Pinia
const store = useInterfaceStore();

// Computed property to check if the sidebar is collapsed
const sidebarCollapsed = computed(() => store.sidebarCollapsed);

// Vue Router hook to get the current route
const route = useRoute();

// Sidebar navigation items
const navigation = reactive([
  { name: "Dashboard", href: "/", icon: HomeIcon, current: true, divider: false },
  { name: "Growth Strategy", href: "/strategy", icon: RocketLaunchIcon, current: false },
  { name: "Growth Plan", href: "/growth-plan", icon: MapIcon, current: false },
  {
    name: "Experiments",
    href: "/experiments",
    icon: BeakerIcon,
    current: false,
    divider: true,
  },
  { name: "Tactics", href: "/tactics", icon: LightBulbIcon, current: false, divider: false },
  { name: "A.I. Tools", href: "/ai-tools", icon: CpuChipIcon, current: false, divider: false },
  // { name: "Playbooks", href: "/playbooks", icon: BookOpenIcon, current: false },
  // { name: "Learn", href: "/learn", icon: AcademicCapIcon, current: false },
  // { name: "Directory", href: "/directory", icon: Squares2X2Icon, current: false },
  // { name: "Tools", href: "/tools", icon: WrenchScrewdriverIcon, current: false },
]);

// Logo data for the sidebar
const logo = {
  src: "/img/logo/logo-white-with-accent.png",
  alt: "Growthmode",
};

// Ref to handle the opening and closing state of the sidebar
const sidebarOpen = ref(false);

// Updates the `current` property of each navigation item based on the current route
function updateCurrentPath() {
  const currentPath = route.path;
  navigation.forEach((item) => {
    item.current = item.href === currentPath;
  });
}

// Lifecycle hook: runs when the component is mounted
onMounted(async () => {
  // Sync user data with the store and update the navigation items
  await syncUser(auth0.user.value);
  updateCurrentPath();
});

// Watcher: updates the navigation items and closes the sidebar whenever the route changes
watch(route, () => {
  updateCurrentPath();
  sidebarOpen.value = false;
});
</script>

<style lang="scss">
// Sidebar collapsed state
.sidebar-collapsed {
  margin-left: 72px;
}

.sidebar-open {
  margin-left: 250px;
}
</style>
