<template>
  <!-- Global notification live region, render this permanently at the end of the document -->
  <teleport to="body">
    <div
      aria-live="assertive"
      class="pointer-events-none fixed inset-0 flex items-start px-4 py-6 sm:items-start sm:p-6"
      style="z-index: 1000"
    >
      <div class="flex w-full flex-col items-center space-y-4 sm:items-end">
        <!-- Notification panel, dynamically insert this into the live region when it needs to be displayed -->
        <transition
          enter-active-class="transform ease-out duration-300 transition"
          enter-from-class="translate-y-2 opacity-0 sm:translate-y-0 sm:translate-x-2"
          enter-to-class="translate-y-0 opacity-100 sm:translate-x-0"
          leave-active-class="transition ease-in duration-100"
          leave-from-class="opacity-100"
          leave-to-class="opacity-0"
        >
          <div
            v-if="show"
            class="pointer-events-auto w-full max-w-sm overflow-hidden rounded-lg bg-white shadow-lg ring-1 ring-black ring-opacity-5"
          >
            <div class="p-4">
              <div class="flex items-start">
                <div
                  :class="[
                    'flex-shrink-0 rounded-full w-6 h-6 flex items-center justify-center',
                    { 'bg-red-500': type === 'error' },
                    { 'bg-green-400': type === 'success' },
                  ]"
                >
                  <!-- Change icon based on type -->
                  <CheckIcon
                    v-if="type === 'success'"
                    class="h-4 w-4 text-white"
                    aria-hidden="true"
                  />
                  <XMarkIcon
                    v-if="type === 'error'"
                    class="h-4 w-4 text-white"
                    aria-hidden="true"
                  />
                  <!-- Add other icons for different types e.g. error and warning -->
                  <!-- ... -->
                </div>
                <div class="ml-3 w-0 flex-1 pt-0.5">
                  <!-- Display the dynamic message -->
                  <p
                    id="notification-message"
                    class="text-sm font-medium text-gray-900"
                    v-html="message"
                  ></p>
                </div>
                <div class="ml-4 flex flex-shrink-0">
                  <button
                    type="button"
                    @click="show = false"
                    class="inline-flex rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                  >
                    <span class="sr-only">Close</span>
                    <XMarkIcon class="h-5 w-5" aria-hidden="true" />
                  </button>
                </div>
              </div>
            </div>
          </div>
        </transition>
      </div>
    </div>
  </teleport>
</template>

<script setup>
import { ref, watch, defineProps, onMounted, nextTick } from "vue";
import { useRouter } from "vue-router";
import { CheckCircleIcon, XCircleIcon } from "@heroicons/vue/24/outline";
import { XMarkIcon, CheckIcon } from "@heroicons/vue/20/solid";
import { notificationState } from "../plugins/notificationPlugin";

const router = useRouter();

const show = ref(false);
const message = ref("");
const type = ref("success");
const autoHide = ref(true);

watch(notificationState, (newValue) => {
  if (newValue) {
    message.value = newValue.message;
    type.value = newValue.type;
    autoHide.value = newValue.autoHide;
    show.value = true;

    nextTick(() => {
      // Use a regex expression to get all the links in the message
      const links = newValue.message.match(/href="([^"]*)/g);

      // Add an event handler to every link to prevent default behaviour
      if (links) {
        links.forEach((link) => {
          const linkUrl = link.match(/href="([^"]*)/)[1];
          const linkElement = document.querySelector(`#notification-message a[href="${linkUrl}"]`);

          linkElement.addEventListener("click", (e) => {
            e.preventDefault();

            // Check if the linkUrl is a relative path
            const isRelativePath = linkUrl.startsWith("/");

            // If it is a relative path, use router.push to navigate
            if (isRelativePath) {
              router.push(linkUrl);
            } else {
              // If it is an absolute path, use window.location.href to navigate
              window.location.href = linkUrl;
            }

            // Close the notification
            show.value = false;
          });
        });
      }
    });

    // Check if autoHide is true before setting the timeout
    if (autoHide.value) {
      setTimeout(() => {
        show.value = false;
      }, 5000);
    }
  } else {
    show.value = false;
  }
});
</script>
