<template>
  <div
    class="text-center flex items-center justify-center flex-col border border-gray-200 bg-white p-16 rounded"
  >
    <div class="flex justify-center text-center h-12 w-12 text-gray-500 mb-3">
      <slot name="icon"></slot>
    </div>
    <h3 class="mt-2 mb-3 font-semibold text-xl text-gray-700">{{ title }}</h3>
    <p class="mt-0 text-gray-500 text-sm">{{ subTitle }}</p>
    <div class="mt-6">
      <gm-button
        :primary="buttonPrimary"
        :label="buttonText"
        :icon="computedButtonIcon"
        @click="handleClick"
      />
    </div>
  </div>
</template>

<script setup>
import { PlusIcon } from "@heroicons/vue/20/solid";
import { computed, defineProps, defineEmits } from "vue";

/*
 * ###############################################################################################################
 * PROPS AND EMITS
 * ###############################################################################################################
 */
const props = defineProps({
  title: {
    type: String,
    default: "No results",
  },
  subTitle: {
    type: String,
    default: "Try adjusting your search or filters to find what you're looking for.",
  },
  buttonText: {
    type: String,
    default: "New project",
  },
  buttonIcon: {
    type: String,
    default: "plus",
  },
  buttonPrimary: {
    type: Boolean,
    default: true,
  },
});

const emit = defineEmits(["buttonClick"]);

/*
 * ###############################################################################################################
 * COMPUTED
 * ###############################################################################################################
 */

const computedButtonIcon = computed(() => {
  if (props.buttonIcon === "none") {
    return null;
  }
  return props.buttonIcon;
});

/*
 * ###############################################################################################################
 * METHODS
 * ###############################################################################################################
 */

function handleClick() {
  emit("buttonClick");
}
</script>
