<template>
  <Menu
    as="div"
    :class="['relative text-left flex flex-shrink-0', { 'lg:w-48': wide }, { 'w-full': fullWidth }]"
    v-slot="{ open }"
  >
    <div class="w-full">
      <label class="block text-sm font-semibold leading-5 text-gray-900 mb-3 mt-2">
        {{ label }}
      </label>
      <MenuButton
        :class="[
          colorClasses,
          'group w-full text-left relative flex items-center',
          baseClasses,
          { 'ring-2 ring-brandDarkBlue-500': open },
          { 'pointer-events-none': disabled },
        ]"
        @click.stop.prevent="toggleOpen"
        @focus="$emit('focus')"
      >
        <div
          v-if="!multiple"
          :class="['w-full overflow-x-hidden overflow-ellipsis relative', { 'h-5': !subLabel }]"
        >
          <div
            :class="[
              'top-0 w-full',
              colorClasses,
              { 'absolute block whitespace-nowrap': !subLabel },
              haveSelectedOption ? 'text-gray-900' : 'text-gray-400',
              { 'opacity-25': haveSelectedOption && disabled },
              { 'opacity-50': !haveSelectedOption && disabled },
            ]"
          >
            {{ actualLabel }}
            <div class="text-gray-500 mt-1 font-normal">
              {{ subLabel }}
            </div>
          </div>
        </div>
        <div v-if="multiple">
          <span v-if="totalSelectedOptions > 0"> {{ totalSelectedOptions }} selected </span
          ><span v-else :class="['text-gray-400', { 'opacity-50': disabled }]">
            {{ actualLabel }}
          </span>
        </div>

        <div
          v-if="(optional && haveSelectedOption) || (multiple && totalSelectedOptions > 0)"
          v-tooltip="{ text: 'Clear selection' }"
          class="absolute right-3 top-2.5"
        >
          <XMarkIcon
            class="-mr-1 h-3.5 w-3.5 text-gray-400 hover:text-gray-600 rounded-full border border-gray-400 hover:border-gray-600"
            aria-hidden="true"
            @click.stop="clearMenu"
          />
        </div>
        <div v-else>
          <ChevronDownIcon
            class="-mr-1 h-5 w-5 text-gray-400 group-hover:text-gray-600"
            aria-hidden="true"
          />
        </div>
      </MenuButton>
      <div v-if="hasError" class="text-sm mt-1 flex w-full justify-start text-red-600">
        {{ hasError }}
      </div>
      <div v-if="hasNote" class="text-xs mt-1 flex w-full text-gray-500">
        {{ note }}
      </div>
    </div>

    <transition
      enter-active-class="transition ease-out duration-100"
      enter-from-class="transform opacity-0 -translate-y-6"
      enter-to-class="transform opacity-100 translate-y-0"
      leave-active-class="transition ease-in duration-0"
      leave-from-class="transform opacity-100"
      leave-to-class="transform opacity-0"
    >
      <MenuItems
        static
        v-show="open"
        :class="[
          'absolute z-10 overflow-auto max-h-64 w-full origin-top-right rounded-md bg-white shadow-lg border border-gray-200  focus:outline-none',
          subLabel ? 'top-32 -mt-3' : 'top-16 mt-6',
          { 'right-auto': position === 'bottomRight' },
          { 'right-0': position === 'bottomLeft' },
          { 'right-0 -mt-48': position === 'topRight' },
          { 'right-auto -mt-48': position === 'topLeft' },
        ]"
      >
        <div class="py-1">
          <input
            v-if="searchable"
            v-model="searchValue"
            type="text"
            class="searchable-input px-4 pt-1.5 pb-2 text-sm border-b border-gray-200 w-full"
            placeholder="Type to search"
            :id="randomId"
            @keyup="searchInput"
          />
          <MenuItem v-for="(option, key) in internalOptions" :key="key" v-slot="{ active, close }">
            <a
              v-if="option.type !== 'heading'"
              href="#"
              @click.prevent="changeSelection(option, close)"
              :class="[
                { 'bg-gray-100 text-gray-900': active && !option.selected },
                {
                  'bg-brandDarkBlue-500 text-white font-semibold':
                    option.selected && type === 'select',
                },
                'flex place-content-between px-4 py-2 text-sm group relative',
              ]"
              :data-value="option.value"
            >
              <div>{{ option.label || option.value }}</div>
              <div
                v-if="option.selected && type === 'select'"
                class="text-white inset-y-0 flex items-center pl-4 absolute right-2"
              >
                <CheckIcon class="h-4 w-4" aria-hidden="true" />
              </div>
            </a>
            <a
              class="flex place-content-between px-4 py-2 text-xs font-semibold text-brandDarkBlue-500 group uppercase"
              v-else
            >
              {{ option.label || option.value }}
            </a>
          </MenuItem>
        </div>
      </MenuItems>
    </transition>
  </Menu>
</template>

<script setup>
import {
  computed,
  defineEmits,
  defineProps,
  nextTick,
  reactive,
  ref,
  onMounted,
  onUnmounted,
  watch,
} from "vue";
import { Menu, MenuButton, MenuItem, MenuItems } from "@headlessui/vue";
import { CheckIcon, ChevronDownIcon, XMarkIcon } from "@heroicons/vue/20/solid";

/**
 * ####################################################################################################################
 * EMITS & PROPS
 * ####################################################################################################################
 */
const emit = defineEmits(["change", "update:modelValue"]);
const props = defineProps({
  modelValue: {
    type: [Array, String],
    default: () => [],
    validator: (value) => {
      return Array.isArray(value) || typeof value === "string" || value == null;
    },
  },
  wide: Boolean,
  fullWidth: Boolean,
  theme: {
    type: String,
    validator: (value) => ["normal", "outline"].includes(value),
    default: "normal",
  },
  position: {
    type: String,
    default: "bottomRight",
    validator(value) {
      // The value must match one of these strings
      return ["bottomLeft", "bottomRight", "topLeft", "topRight"].includes(value);
    },
  },
  label: String,
  subLabel: String,
  disabled: {
    type: Boolean,
    default: false,
  },
  placeholder: {
    type: String,
    default: null,
  },
  options: {
    type: Array,
    validator: (value) => {
      // Check if the value is an array
      if (!Array.isArray(value)) {
        return false;
      }

      // Check if each item in the array has the required properties
      for (let i = 0; i < value.length; i++) {
        const item = value[i];

        // Check if the item is an object
        if (typeof item !== "object" || Array.isArray(item) || item === null) {
          return false;
        }

        // Check if the item has the required properties
        if (!("value" in item) || !("selected" in item)) {
          return false;
        }
      }

      // All items are valid
      return true;
    },
    default: () => [],
  },
  type: {
    type: String,
    validator: (value) => ["menu", "select"].includes(value),
    default: "select",
  },
  note: {
    type: String,
    default: null,
  },
  // If set to true, will prepend the label to the selected label
  showLabelBeforeSelected: {
    type: String || null,
    default: null,
  },
  optional: {
    type: Boolean,
    default: false,
  },
  searchable: {
    type: Boolean,
    default: false,
  },
  addable: {
    type: Boolean,
    default: false,
  },
  multiple: {
    type: Boolean,
    default: false,
  },
  hasError: {
    type: String,
    default: null,
  },
});

/**
 * ####################################################################################################################
 * REACTIVE
 * ####################################################################################################################
 */
const open = ref(false); // Whether the menu is open or not
const randomId = ref(""); // A random ID for the input field if searchable
const originalOptions = ref([]); // The original options passed in as a prop
const searchValue = ref(""); // The value of the search input field
const internalOptions = reactive(props.options); // The options that are used internally

/**
 * ####################################################################################################################
 * COMPUTED
 * ####################################################################################################################
 */

// The actual label to display
const actualLabel = computed(() => {
  if (props.type === "select") {
    // Check the modelValue
    const modelValue = props.modelValue;

    const selected = internalOptions.find((option) => {
      if (props.multiple) {
        return modelValue.includes(option.value);
      } else {
        return option.value === modelValue;
      }
    });

    if (selected) {
      return selected.label || selected.value;
    } else if (props.placeholder) {
      return props.placeholder;
    } else {
      if (props.optional) {
        return `Select ${props.label.toLowerCase()}`;
      } else {
        return props.label;
      }
    }
  }

  // Otherwise return the label from props

  return props.label;
});

// Calculate the classes for the color if the option has a color property
const colorClasses = computed(() => {
  if (props.type !== "select") {
    return;
  }

  const modelValue = props.modelValue;

  const selected = internalOptions.find((option) => {
    if (props.multiple) {
      return modelValue.includes(option.value);
    } else {
      return option.value === modelValue;
    }
  });

  if (selected && selected.color) {
    if (selected.color === "gray") {
      return "bg-gray-100 text-gray-500";
    } else if (selected.color === "blue") {
      return "bg-blue-100 text-blue-600";
    } else if (selected.color === "green") {
      return "bg-green-100 text-green-600";
    } else if (selected.color === "blue") {
      return "bg-blue-100 text-blue-600";
    } else if (selected.color === "orange") {
      return "bg-yellow-100 text-yellow-600";
    }
    return "bg-gray-100 text-gray-500";
  }

  if (props.theme === "outline") {
    return "bg-transparent text-gray-500";
  }
  return "bg-gray-100";
});

// The base classes for the menu button
const baseClasses = computed(() => {
  let classes =
    "inline-flex w-full min-h-10 items-center place-content-between rounded-md px-3 py-3 text-sm font-normal text-black";

  if (props.theme === "outline") {
    classes += " bg-transparent ring-0 border border-gray-300 text-gray-500";
    if (props.subLabel) {
      classes += " font-semibold";
    }
  }

  return classes;
});

// Whether there is a selected option or not
const haveSelectedOption = computed(() => {
  if (props.type !== "select") {
    return;
  }

  return !!internalOptions.find((option) => option.selected);
});

// Total number of selected options
const totalSelectedOptions = computed(() => {
  return internalOptions.filter((option) => option.selected).length;
});

// Whether the menu has a note or not
const hasNote = computed(() => props.note?.length > 0);

/**
 * ####################################################################################################################
 * METHODS
 * ####################################################################################################################
 */

// Toggle the open value
function toggleOpen() {
  open.value = !open.value;
}

// Generate a random UUID
function generateRandomUUID() {
  return Math.random().toString(36).substring(2, 15);
}

// Filter the options based on the search input value
function searchInput() {
  // Get the value of the input
  const value = searchValue.value;
  const originalOptionsCopy = [...originalOptions.value];

  // Filter the options based on the input value
  const filteredOptions = originalOptionsCopy.filter((option) => {
    const labelOrValue = option.label ? option.label : option.value;
    return labelOrValue.toLowerCase().includes(value.toLowerCase());
  });

  // Set the internalOptions to the filtered options
  internalOptions.splice(0, internalOptions.length, ...filteredOptions);
}

// Handle the selection of an option
function changeSelection(selection, closeMenu) {
  if (props.multiple) {
    // Multiple selection logic
    const newOption = internalOptions.find((option) => option.value === selection.value);

    // Toggle the selected state of the chosen option
    newOption.selected = !newOption.selected;

    // Update modelValue based on the selected state of options in internalOptions
    const selectedValues = internalOptions
      .filter((option) => option.selected)
      .map((option) => option.value);

    emit("update:modelValue", selectedValues);

    // For multiple selection, you may choose not to close the menu immediately
    // If you want to close the menu, uncomment the next line
    // closeMenu();
  } else {
    // Single selection logic (as it was before)
    const newOption = internalOptions.find((option) => option.value === selection.value);
    const selectedOption = internalOptions.find((option) => option.selected);
    newOption.selected = true;

    if (selectedOption && selectedOption.value !== newOption.value) {
      selectedOption.selected = false;
    }

    emit("change", newOption.value);
    emit("update:modelValue", newOption.value);

    closeMenu();
    open.value = false;
  }

  // Reset the search value and options (common for both single and multiple selections)
  setTimeout(() => {
    searchValue.value = "";
    setInternalOptionsToOriginalOptions();
  }, 200);
}

function clearMenu() {
  if (props.multiple) {
    // Multiple selection logic
    internalOptions.forEach((option) => {
      option.selected = false;
    });

    emit("update:modelValue", []);
  } else {
    // Single selection logic (as it was before)
    const selectedOption = internalOptions.find((option) => option.selected);
    if (selectedOption) {
      selectedOption.selected = false;
    }

    emit("change", null);
    emit("update:modelValue", null);
  }

  // Reset the search value and options (common for both single and multiple selections)
  setTimeout(() => {
    searchValue.value = "";
    setInternalOptionsToOriginalOptions();
  }, 200);
}

// Close the menu and remove the focus state
function closeAndRemoveFocusState() {
  open.value = false;
}

// Update the originalOptions
function updateOriginalOptions() {
  originalOptions.value = [...props.options];
}

// Set the internalOptions to the originalOptions
function setInternalOptionsToOriginalOptions() {
  internalOptions.splice(0, internalOptions.length, ...originalOptions.value);
}

// Function to read the modelValue and go through all internalOptions and set the selected state accordingly
function setSelectedState() {
  const modelValue = props.modelValue;

  // If the modelValue is an array, it means that multiple selection is enabled
  if (Array.isArray(modelValue)) {
    // Go through all internalOptions and set the selected state based on the modelValue
    internalOptions.forEach((option) => {
      option.selected = modelValue.includes(option.value);
    });
  } else {
    // Go through all internalOptions and set the selected state based on the modelValue
    internalOptions.forEach((option) => {
      option.selected = option.value === modelValue;
    });
  }
}

/*
 * ####################################################################################################################
 * WATCHERS
 * ####################################################################################################################
 */

// Watch the computed open value for changes and focus on the input field if type is currency
watch(
  () => open.value,
  (newValue) => {
    if (newValue) {
      if (props.searchable) {
        setTimeout(() => {
          const input = document.getElementById(randomId.value);
          input.focus();
        }, 100);
      }
    }
  }
);

// Watch the options prop for changes and update the originalOptions, using "deep" option
watch(
  () => props.options,
  (newValue) => {
    updateOriginalOptions();
    setInternalOptionsToOriginalOptions();
  },
  { deep: true }
);

// Watch the modelValue prop for changes and update the selected state of internalOptions
watch(
  () => props.modelValue,
  (newValue) => {
    setSelectedState();
  },
  { deep: true }
);

/*
 * ####################################################################################################################
 * LIFECYCLE HOOKS
 * ####################################################################################################################
 */

onMounted(() => {
  updateOriginalOptions();
  randomId.value = generateRandomUUID();

  const elements = document.querySelectorAll("body, .modal-body");
  elements.forEach((el) => {
    el.addEventListener("click", closeAndRemoveFocusState);
  });
});

onUnmounted(() => {
  const elements = document.querySelectorAll("body, .modal-body");
  elements.forEach((el) => {
    el.removeEventListener("click", closeAndRemoveFocusState);
  });
});
</script>
